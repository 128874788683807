import { BrowserRouter, Switch, Route } from 'react-router-dom'

import LandingPage from './components/LandingPage'
import LoginPage from './components/LoginPage'
import RegisterPage from './components/RegisterPage'
import Home from './components/Home'
import HistoryPage from './components/HistoryPage'
import EmailVerification from './components/EmailVerification'
import UpdatePassword from './components/UpdatePassword'
import ProfilePage from './components/ProfilePage'
import TransactionHistory from './components/TransactionHistory'
import ProtectedRoute from './components/ProtectedRoute'

const App = () => {


  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/verify' component={EmailVerification}/>
          <Route exact path='/register' component={RegisterPage} />
          <Route exact path='/updatepassword' component={UpdatePassword}/>
          <ProtectedRoute exact path='/profile' component={ProfilePage}/>
          <ProtectedRoute exact path='/home' component={Home} />
          <ProtectedRoute exact path='/transactionhistory' component={TransactionHistory}/>
          <ProtectedRoute exact path='/history' component={HistoryPage} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;