import {useState} from "react"
import Popup from 'reactjs-popup'
import Cookies from 'js-cookie'

import { CiImageOn } from "react-icons/ci";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import './index.css'

const OrderItem = props =>{
    const {value,handelPrintedBtn,handelCancelBtn,handelCompletedBtn} = props
    const {email,orderId,customerName,files,amount,status,customOrderId} = value

    const [otp,setOtp] = useState("")

    const onChangeOtp = (event) =>{
        setOtp(event.target.value);
    }

    //To open a file CLICK ON FILE

    const handleFile = async (selectedFile) => {
        const token = Cookies.get("mitra_token")
        const url = `https://api.studentmitra.fun/api/shopservices/xeroxshops/order/${orderId}/download?fileName=${selectedFile}`
        const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            method: 'GET',
          }
        const response = await fetch(url,options)
        const blob = await response.blob() 
        const URL = window.URL.createObjectURL(blob)
        window.open(URL, '_blank');
    };


    //to print a file PRINT BUTTON

    const handlePrint = async (selectedFile) => {
        const token = Cookies.get("mitra_token")
        const url = `https://api.studentmitra.fun/api/shopservices/xeroxshops/order/${orderId}/download?fileName=${selectedFile}`
        const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            method: 'GET',
          }
        const response = await fetch(url,options)
        const blob = await response.blob() 
        const URL = window.URL.createObjectURL(blob)
        

        const printWindow = window.open(URL, '_blank');
        printWindow.onload = function() {
            printWindow.focus();
            printWindow.print();
        };

    };

    //Notification PRINTED BUTTON
    const onClickPrinted = () => {
        handelPrintedBtn(orderId)
    };

    //cancle Order 
    const cancleOrder = (close) =>{
        handelCancelBtn(orderId)
        close()
    }

    //push to history COMPLETED BUTTON
    const handleProceed = () =>{
        handelCompletedBtn(orderId,otp)
    }


    return(
        <li className="order-list">
            <div className="order-list-item">
                <div className="item-details">
                    <div className="order-img">
                        <CiImageOn />
                    </div>
                    <div className="name-container">
                        <h1 className="order-name">{customerName}</h1>
                        <p className="order-date">{status}</p>
                    </div>
                </div>
                <p className="custom-order-id">{customOrderId}</p>
                <p className="price">{amount}</p>
                <ul className="chapter-container">
                    {files.map(eachItem => <li className="chapter" onClick={() => handleFile(eachItem.selectedFile)} key={eachItem.filesId}>
                        <p>{eachItem.selectedFile}</p>
                    </li>)}
                </ul>
            </div>
            <div className="items">
                <Popup
                    modal
                    trigger={
                    <button type="button" className="trigger-button cross-btn">
                        <AiOutlineInfoCircle />
                    </button>
                    }
                    >
                    {close => (
                    <>
                        <div className="popup-container">
                            <div className="item-details">
                                <div className="order-img">
                                    <CiImageOn />
                                </div>
                                <div>
                                    <h1 className="order-name">{customerName}</h1>
                                    <p className="order-date">{email}</p>
                                </div>
                            </div>
                            <ul className="items-detail-list">
                                {files.map(eachItem => (
                                    <li className="chapter-detailed-view" key={eachItem.filesId}>
                                        <div className="popup-chapter-container">
                                            <div className="popup-chapter" onClick={() => handleFile(eachItem.selectedFile)}>
                                                <p>{eachItem.selectedFile}</p>
                                            </div>
                                            <div className="popup-chapter-price">
                                                <p>{eachItem.fileCost} rs</p>
                                            </div>
                                        </div>
                                        <p className="total-copies">Print Type: <span className='span-copies'>{eachItem.printType}</span></p>
                                        <p className="total-copies">Sides: <span className='span-copies'>{eachItem.sides}</span></p>
                                        <p className="total-copies">Pages: <span className='span-copies'>{eachItem.Pages}</span></p>
                                        <p className="total-copies">Custom Pages: <span className='span-copies'>{eachItem.customPages}</span></p>
                                        <p className="total-copies">Total Copies: <span className='span-copies'>{eachItem.totalCopies}</span></p>
                                        <button onClick={() => handlePrint(eachItem.selectedFile)} className='popup-print-btn'>Print</button>
                                    </li>
                                ))}
                            </ul>
                            <div className="popup-btns-container">
                                <button className="popup-print-all-btn" onClick={onClickPrinted}>Printed</button>
                                <button className="popup-close-all-btn" onClick={() => close()}>Close</button>
                            </div>
                        </div>
                    </>
                    )}
                </Popup>
                <Popup
                    modal
                    trigger={
                        <button type="button" className="cross-btn"><RxCross2 /></button>
                    }
                >
                    {close => (
                    <>
                        <div className='completed-popup'>
                            <p>Reject Order</p>
                            <button type="button" className="print-all-btn" onClick={() => cancleOrder(close,{orderId})}>
                                Confirm
                            </button>
                        </div>
                    </>
                    )}
                </Popup>
                <Popup
                    modal
                    trigger={
                        <button type="button" className="print-all-btn">Completed</button>
                    }
                >
                    {close => (
                    <>
                        <div className='completed-popup'>
                            <p>Enter OTP:</p>
                            <input type="text" onChange={onChangeOtp} placeholder='ENTER OTP' /> <br />
                            <div className="popup-btn-container">
                                <button type="button" className="print-all-btn" onClick={() => close()}>
                                    Cancel
                                </button>
                                <button type="button" className="print-all-btn" onClick={() => handleProceed(close)}>Proceed</button>
                            </div>
                        </div>
                    </>
                    )}
                </Popup>
            </div>
        </li>
    )
}

export default OrderItem