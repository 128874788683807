import {Component} from 'react'
import { FaGlobe, FaHandshake } from "react-icons/fa"
import { ImProfile } from "react-icons/im"
import { FaPeopleGroup } from "react-icons/fa6"
import Footer from '../Footer'
import './index.css'

class LandingPage extends Component{

    onClickSignIn =  () =>{
        const {history} = this.props 
        history.push('/login')
    }

    onClickSignUp = () =>{
        const {history} = this.props 
        history.push('/register')
    }

    render() {

    return (
        <div className="landing-page-container">
            <div className='header-section'>
                <img
                    src="https://res.cloudinary.com/dwma8dkv0/image/upload/v1723373332/WhatsApp_Image_2024-08-11_at_16.17.14_d7e20499_pen9lh.jpg"
                    alt="logo"
                    className="logo"
                />
                <div className='header-right-section'>
                    <button className='sign-btn' onClick={this.onClickSignIn}>SIGN IN</button>
                    <button className='sign-btn' onClick={this.onClickSignUp}>SIGN UP</button>
                </div>
            </div>
            <div className="banner-section">
                <img src="https://res.cloudinary.com/dwma8dkv0/image/upload/v1723387839/WhatsApp_Image_2024-08-11_at_16.35.02_f0f8fd1b_lky7yb.jpg" className="banner-img" alt="banner-img" />
                <div className="banner-txt">
                    with us <span className="banner-blue-txt">MITRA </span>
                    Stay ahead of the competition
                    and <span className="banner-red-txt">digitalize </span>your <span className="banner-red-txt">photocopy shop </span>
                    online
                </div>
            </div>
            <div className="special-features">
                <div className="feature-item">
                    <FaGlobe className="feature-icon" />
                    <p>Build online presence</p>
                </div>
                <div className="feature-item">
                    <ImProfile className="feature-icon" />
                    <p>Increase Creadibility</p>
                </div>
                <div className="feature-item">
                    <FaPeopleGroup className="feature-icon" />
                    <p>Extended Reach</p>
                </div>
                <div className="feature-item">
                    <FaHandshake className="feature-icon" />
                    <p>Improve Bonding</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
}
export default LandingPage