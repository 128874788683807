import { FaGoogle, FaTwitter, FaInstagram, FaLinkedin, FaPhoneAlt } from "react-icons/fa"
import { IoMail } from "react-icons/io5"
import './index.css'

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="media-section">
                <div className="social-media">
                    <button className="media-btn">
                        <FaGoogle className="media-icon" />
                    </button>
                    <button className="media-btn">
                        <FaTwitter className="media-icon" />
                    </button>
                    <button className="media-btn">
                        <FaInstagram className="media-icon" />
                    </button>
                    <a href="https://www.linkedin.com/company/gyaantrix-technologies-private-limited/" target="_blank" rel="noopener noreferrer">
                        <button className="media-btn">
                            <FaLinkedin className="media-icon" />
                        </button>
                    </a>
                </div>
                <p className="media-para">37, Ayur Vigyan Nagar, New Delhi, India</p>
            </div>
            <div className="footer-details-section">
                <div className="contact-us-section">
                    <h1 className="contact-section-heading">Contact Us</h1>
                    <a className="anchor-link" href="mailto:customer@gtpl.me">
                        <div className="contact-item">
                            <IoMail className="contact-icon" />
                            <p>customer@gtpl.me</p>
                        </div>
                    </a>
                    <a className="anchor-link" href="https://wa.me/9346977071">
                        <div className="contact-item">
                                <FaPhoneAlt className="contact-icon" />
                                <p>9346977071</p>
                        </div>
                    </a>
                </div>
                <div className="help-section">
                    <h1 className="contact-section-heading">Know more about us</h1>
                    <p className="help-para">About MITRA</p>
                    <p className="help-para">About GTPL</p>
                    <p className="help-para">Founders Desk</p>
                </div>
            </div>
        </div>
    )
}

export default Footer