import { Link, withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { FaHome, FaHistory } from 'react-icons/fa'
import { FaUser } from "react-icons/fa";
import { IoMdLogOut } from 'react-icons/io'
import './index.css'

const Header = props => {
  const { location } = props

  const onLogout = async () => {

    const token = Cookies.get("mitra_token")
        const url = "https://api.studentmitra.fun/api/shopservices/xeroxshops/toggle-status";
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "PUT",
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            if(data.isOpen){
              onLogout()
            }
            console.log("shop status updated")
        }
        else {
            console.log(data)
            console.log("can not update shop status")
        }

    const {history} = props
    Cookies.remove('mitra_token')
    history.push('/')
  }

  return (
    <div className="nav-bar">
      <img
        src="https://res.cloudinary.com/dwma8dkv0/image/upload/v1723373332/WhatsApp_Image_2024-08-11_at_16.17.14_d7e20499_pen9lh.jpg"
        alt="logo"
        className="logo"
      />
      <div className="nav-options-mobile-view">
        <Link to="/home">
          <button
            type="button"
            className={`nav-link ${location.pathname === '/home' ? 'active-mobile' : ''
              }`}
          >
            <FaHome />
          </button>
        </Link>
        <Link to="/profile">
          <button
            type="button"
            className={`nav-link ${location.pathname === '/profile' ? 'active-mobile' : ''
              }`}
          >
            <FaUser />
          </button>
        </Link>
        <Link to="/history">
          <button
            type="button"
            className={`nav-link ${location.pathname === '/history' ? 'active-mobile' : ''
              }`}
          >
            <FaHistory />
          </button>
        </Link>
        <button type="button" onClick={onLogout} className="nav-link">
          <IoMdLogOut />
        </button>
      </div>
      <div className="nav-options-desktop-view">
        <Link to="/home">
          <button
            type="button"
            className={`nav-link-desktop ${location.pathname === '/home' ? 'active-desktop' : ''
              }`}
          >
            Home
          </button>
        </Link>
        <Link to="/profile">
          <button
            type="button"
            className={`nav-link-desktop ${location.pathname === '/profile' ? 'active-desktop' : ''
              }`}
          >
            Profile
          </button>
        </Link>

        <Link to="/history">
          <button
            type="button"
            className={`nav-link-desktop ${location.pathname === '/history' ? 'active-desktop' : ''
              }`}
          >
            History
          </button>
        </Link>
      </div>
      <button type="button" onClick={onLogout} className="desktop-logout-btn">
        Logout
      </button>
    </div>
  )
}

export default withRouter(Header)
