import {Component} from 'react'
import Cookies from 'js-cookie'
import { Oval } from 'react-loader-spinner';
import Header from '../Header'
import Footer from '../Footer'
import HistoryItem from '../HistoryItem'

import { parse } from 'date-fns';

import './index.css'

const apiStatus = {
    initial:"INITIAL",
    inProgress:"INPROGRESS",
    success:"SUCCESS",
    failure:"FAILURE",
}

class HistoryPage extends Component{
    state = {list:[],status:apiStatus.success}

    componentDidMount = async () =>{
        this.getData()
    }

    getData = async () =>{
        this.setState({status:apiStatus.inProgress})
        const token = Cookies.get("mitra_token");

        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders-history`
        const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            method: 'GET',
        }
        const response = await fetch(url,options)
        const data = await response.json()
        if(response.ok){
            console.log(data)
            const sortedData = data.sort((a, b) => {
                const dateA = parse(a.date, "d MMMM yyyy 'at' h:mm a", new Date());
                const dateB = parse(b.date, "d MMMM yyyy 'at' h:mm a", new Date());
                return dateB - dateA; // Sort from latest to oldest
            });
            this.setState({list:sortedData,status:apiStatus.success})
        }
        else{
            this.setState({status:apiStatus.failure})
        }
    }


    onClickRetry = () => {
        window.location.reload()
    }

    render() {
        const {list,status} = this.state

        let code

        switch(status){
            case apiStatus.inProgress:
                code = (
                    <div className='oval-loader'>
                        <Oval height={45} width={45} color="#0d4c8c" ariaLabel="loading"/>
                    </div>
                )
                break
            case apiStatus.success:
                if(list.length===0){
                    code = (
                        <div className="no-orders-view">
                          <img
                            src="https://assets.ccbp.in/frontend/react-js/no-jobs-img.png"
                            alt="no orders"
                            className='no-orders-view-img'
                          />
                          <h1>No History Found</h1>
                          <p>We could not find any Orders History.</p>
                        </div>
                      )
                } else{
                    code = (
                        <ul className='history-ul-list-container'>
                            {list.map(eachItem => <HistoryItem value={eachItem} key={eachItem.orderId} />)}
                        </ul>
                    )
                }
                break
            case apiStatus.failure:
                code = (
                    <div className="no-orders-view">
                      <img
                        src="https://assets.ccbp.in/frontend/react-js/failure-img.png"
                        alt="failure view"
                        className='no-orders-view-img'
                      />
                      <h1>Oops! Something Went Wrong</h1>
                      <p>We cannot seem to find the page you are looking for</p>
                      <button className="retry-btn" onClick={this.onClickRetry} type="button">
                        Retry
                      </button>
                    </div>
                  )
                break 
            default : 
                code = null 
        }

        return(
            <div>
                <Header />
                <div className="history-page-container">
                    <div>
                        <p className='store-details'>STORE ID: <span className='span-store'>XXXX</span></p>
                        <p className='store-details'>LOCATION: <span className='span-store'>XXXXXXXX</span></p>
                    </div>
                    <div className='history-bottom-container'>
                        <h1 className='history-heading'>History:</h1>
                        {code}
                        {(status==="SUCCESS" && list.length!==0) ? <button className="history-btn">Clear History</button> : null}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default HistoryPage