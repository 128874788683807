import { Component } from 'react'
import { Link } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'
import { FaThumbsUp } from "react-icons/fa"
import './index.css'

const apiStatusConstants = {
    initial: 'INITIAL',
    inProgress: 'IN_PROGRESS',
}

class RegisterPage extends Component {
    state = {
        name:'',
        storeName: '',
        mobile: '',
        email: '',
        password:'',
        confirmPassword:'',
        successMsg: '',
        errMsg: '',
        apiStatus: apiStatusConstants.initial,
        latitude: 0, longitude: 0, locationError: '',
        storeAddress:''
    }

    componentDidMount = () =>{
        this.getCurrentLocation()
    }

    getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.setState({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }, resolve);
                    },
                    error => {
                        let errorMessage = '';
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                errorMessage = 'Location access was denied. Showing results based on default location.';
                                break;
                            case error.POSITION_UNAVAILABLE:
                                errorMessage = 'Location information is unavailable. Showing results based on default location.';
                                break;
                            case error.TIMEOUT:
                                errorMessage = 'Location request timed out. Showing results based on default location.';
                                break;
                            default:
                                errorMessage = 'An unknown error occurred. Showing results based on default location.';
                                break;
                        }
                        this.setState({ locationError: errorMessage });
                        console.warn('Geolocation Error:', error);
                        resolve();  // Resolve even if there's an error to avoid the error message being thrown
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 10000,
                        maximumAge: 0
                    }
                );
            } else {
                const errorMsg = 'Geolocation is not supported by this browser.';
                this.setState({ locationError: errorMsg });
                resolve();  // Resolve since there's no geolocation support
            }
        });
    }

    onChange = (e) => {
        if (e.target.value !== '') {
            this.setState({ errMsg: '' })
        }
        this.setState({ [e.target.id]: e.target.value })
    }

    onSuccessRegistration = () => {
        this.setState({
            name:'',
            storeName: '',
            mobile: '',
            email: '',
            storeAddress:'',
            password: '',
            confirmPassword:'',
            successMsg: 'You would be contacted soon',
            apiStatus: apiStatusConstants.initial,
        })
    }

    validations = () => {
        const { name,storeName, mobile, email,storeAddress,latitude,longitude,password,confirmPassword} = this.state

        if (storeName === '') return '*Please enter the storeName'
        else if (mobile.length !== 10) return '*Please enter valid mobile number'
        else if (email === '') return '*please enter the email'
        else if (name === '') return '*Please enter the name'
        else if (storeAddress === '') return '*Please enter the storeAddress'
        else if (latitude === 0) return '*Please allow location access'
        else if (longitude === 0) return '*Please allow location access'
        else if (password === '') return '*please enter the password'
        else if (password.length < 6) return '*Password should have at least 6 characters'
        else if (confirmPassword !== password) return '*Password mismatch'
        else return ''
    }

    onSubmitRegisterForm = async event => {
        event.preventDefault()
        const { name,storeName, mobile,password, email,storeAddress,latitude,longitude} = this.state

        const validated = this.validations()
        if (validated !== '') {
            this.setState({ errMsg: validated })
            return
        }

        const userDetails = {
            name,
            shopName:storeName,
            mobileNo: mobile,
            email,
            password,
            shopAddress:storeAddress,
            geoLocation:{
                type:"Point",
                coordinates:[latitude,longitude]
            }
        }

        this.setState({ apiStatus: apiStatusConstants.inProgress })
        const url = `${process.env.REACT_APP_BASE_URL}/shop/register`
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userDetails),
        }
        console.log("maneesh")
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok === true) {
            this.onSuccessRegistration()
        } else {
            this.setState({ errMsg: data.msg, apiStatus: apiStatusConstants.initial })
        }
    }

    loadingView = () => (
        <div className="loading-section" data-testid="loader">
            <TailSpin color="#0b69ff" height={80} width={80} />
        </div>
    )

    registerFormView = () => {
        const { name,storeName,confirmPassword, mobile, email,storeAddress,password, successMsg, errMsg } = this.state
        return (
            <div className="register-form-content">
                {!successMsg &&
                    <>
                        <h1 className="register-heading">Register Here</h1>
                        <div className="register-input-set">
                            <label className="register-label" htmlFor="name">Name</label><br />
                            <input
                                value={name}
                                className="register-input"
                                onChange={this.onChange}
                                placeholder="ENTER NAME"
                                type="text"
                                id="name"
                            />
                        </div>
                        <div className="register-input-set">
                            <label className="register-label" htmlFor="storeName">Store Name</label><br />
                            <input
                                value={storeName}
                                className="register-input"
                                onChange={this.onChange}
                                placeholder="ENTER STORE NAME"
                                type="text"
                                id="storeName"
                            />
                        </div>
                        <div className="register-input-set">
                            <label className="register-label" htmlFor="storeAddress">Store Address</label><br />
                            <input
                                value={storeAddress}
                                className="register-input"
                                onChange={this.onChange}
                                placeholder="ENTER STORE ADDRESS"
                                type="text"
                                id="storeAddress"
                            />
                        </div>
                        <div className="register-input-set">
                            <label className="register-label" htmlFor="mobile">Mobile</label><br />
                            <input
                                value={mobile}
                                className="register-input"
                                onChange={this.onChange}
                                placeholder="ENTER NUMBER"
                                type="number"
                                id="mobile"
                            />
                        </div>
                        <div className="register-input-set">
                            <label className="register-label" htmlFor="email">Email</label><br />
                            <input
                                value={email}
                                className="register-input"
                                onChange={this.onChange}
                                placeholder="ENTER EMAIL"
                                type="text"
                                id="email"
                            />
                        </div>
                        <div className="register-input-set">
                            <label className="register-label" htmlFor="password">Password</label><br />
                            <input
                                value={password}
                                className="register-input"
                                onChange={this.onChange}
                                placeholder="ENTER PASSWORD"
                                type="password"
                                id="password"
                            />
                        </div>
                        <div className="register-input-set">
                            <label className="register-label" htmlFor="confirmPassword">confirmPassword</label><br />
                            <input
                                value={confirmPassword}
                                className="register-input"
                                onChange={this.onChange}
                                placeholder="CONFIRM PASSWORD"
                                type="password"
                                id="confirmPassword"
                            />
                        </div>
                        <button type="submit" className="register-btn">Proceed to verify</button>
                        {errMsg && <p className='register-error-msg'>{errMsg}</p>}
                    </>
                }
                {successMsg &&
                    <div className='success-view'>
                        <p className='thumb-icon'><FaThumbsUp /></p>
                        <p className='register-success-msg'>{successMsg}</p>
                        <Link to='/'>
                            <button className='back-to-login-btn'>Back to Home</button>
                        </Link>
                    </div>
                }
            </div>
        )
    }

    render() {
        const { apiStatus } = this.state
        let view

        switch(apiStatus) {
            case apiStatusConstants.initial:
                view = this.registerFormView()
                break
            case apiStatusConstants.inProgress:
                view = this.loadingView()
                break 
            default:
                view = null    
        }
        return (
            <div className="register-container">
                <form className="register-form-container" onSubmit={this.onSubmitRegisterForm}>
                    <img
                        src="https://res.cloudinary.com/dwma8dkv0/image/upload/v1725112862/mitra_white_bgm_vdejyb.jpg"
                        alt="logo"
                        className="register-page-img"
                    />
                    {view}
                </form>
            </div>
        )
    }
}

export default RegisterPage