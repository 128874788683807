import {Redirect} from 'react-router-dom'
import { Component } from 'react'
import Cookies from 'js-cookie'
import './index.css'

class LoginPage extends Component {
    state = { email: '', password: '', showSubmitError: '' }

    onChangeEmail = event => this.setState({ email: event.target.value })

    onChangePassword = event => this.setState({ password: event.target.value })

    onClickForgotPassword = () =>{
        const {history} = this.props 
        history.push('/verify')
    }

    logToRegister = () =>{
        const {history} = this.props 
        history.push('/register')
    }

    onSuccessLogin = (token) => {
        const {history} = this.props
        
        Cookies.set('mitra_token', token, {expires: 30, path: '/'})
        history.replace('/home')
    }

    onSubmitLoginForm = async event => {
        event.preventDefault()
        const { email, password } = this.state

        // Validation
        if (email === '' || password === '') {
            this.setState({ showSubmitError: 'id and Password are required' })
            return
        }   

        const userDetails = { email, password}
        const url = `${process.env.REACT_APP_BASE_URL}/shop/login`
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userDetails),
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok === true) {
            this.onSuccessLogin(data.token)
        } else {
            this.setState({showSubmitError: data.msg})
        }
    }

    render() {
        const jwtToken = Cookies.get('mitra_token')
        if(jwtToken !== undefined){
            return <Redirect to='/home' />
        }
        const { showSubmitError, email, password, } = this.state
        return (
            <div className='login-container'>
                <form className='form-container' onSubmit={this.onSubmitLoginForm}>
                    <img src="https://res.cloudinary.com/dwma8dkv0/image/upload/v1725112862/mitra_white_bgm_vdejyb.jpg" alt="logo" className='login-page-img' />
                    <div className='form-content'>
                        <div className='input-set'>
                            <label className='label' htmlFor="email">EMAIL</label><br />
                            <input className='input' value={email} onChange={this.onChangeEmail} placeholder='Value' type="text" id="email" />
                        </div>
                        <div className='input-set'>
                            <label className='label' htmlFor="password">PASSWORD</label><br />
                            <input className='input' value={password} onChange={this.onChangePassword} placeholder='Password' type="password" id="password" />
                        </div>
                        <p className='forgot-password' onClick={this.onClickForgotPassword}>Forgot Password?</p>
                        <button type="submit" className='login-btn'>Sign In</button>
                        {showSubmitError && <p className="login-err">{showSubmitError}</p>}
                        <p className='create-account' onClick={this.logToRegister}>Create Account</p>
                    </div>
                </form>
            </div>
        )
    }
}

export default LoginPage