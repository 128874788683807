import { RxCross2 } from "react-icons/rx";
import { LiaPiggyBankSolid } from "react-icons/lia";

import './index.css'

const HistoryItem = props =>{
    const {value} = props 
    const {customerName,email,status,amount} = value

    return(
        <li className="history-list-item">
            <div className="history-item-details">
                <h1 className="history-name">{customerName}</h1>
                <p className="history-email">{email}</p>
            </div>
            <p className="history-type">{status}</p>
            <div className="items">
                <LiaPiggyBankSolid className="pig" />
                <p className="price">{amount}</p>
                <button className="price-btn">{amount}</button>
                <button type="button" className="cross-btn"><RxCross2 /></button>               
            </div>
        </li>
    )
}

export default HistoryItem