import {Link} from 'react-router-dom'
import { Component } from 'react'
import Cookies from 'js-cookie';
import Popup from 'reactjs-popup'
import { Oval } from 'react-loader-spinner';
import { Switch } from 'antd'

import Header from '../Header'
import Footer from '../Footer'
import OrderItem from '../OrderItem'

import { IoWalletOutline } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";

import { parse } from 'date-fns';

import './index.css'

const apiStatus = {
    initial: "INITIAL",
    inProgress: "INPROGRESS",
    success: "SUCCESS",
    failure: "FAILURE",
}

const withdrawalStatusConstants = {
    initial: 'INITIAL',
    inProgress: 'INPROGRESS',
    completed: 'COMPLETED'
}

class Home extends Component {
    state = { isActive: false, searchValue: '', list: [], status: apiStatus.initial, selectedOption: 'all', walletDetails: {}, withdrawlAmount: 0, withdrawalStatus: withdrawalStatusConstants.initial, withDrawResult: {} }

    componentDidMount = async () => {
        this.getData()
        this.getCurrentBalance()
    }

    getCurrentBalance = async () => {
        const token = Cookies.get('mitra_token')
        const url = `${process.env.REACT_APP_BASE_URL}/profile/shop-home`
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok === true) {
            this.setState({ walletDetails: data,isActive:data.isOpen })
        } else {
            console.log("Got error while fetching")
        }
    }

    getData = async () => {
        this.setState({ status: apiStatus.inProgress })
        const token = Cookies.get("mitra_token");

        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
                const sortedData = data.sort((a, b) => {
                const dateA = parse(a.date, "d MMMM yyyy 'at' h:mm a", new Date());
                const dateB = parse(b.date, "d MMMM yyyy 'at' h:mm a", new Date());
                return dateA - dateB; // Sort from oldest to newest
            });
            this.setState({ list: sortedData, status: apiStatus.success })
        }
        else {
            this.setState({ status: apiStatus.failure })
        }
    }

    onChangeActive = async () => {
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/toggle-status`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "PUT",
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            console.log("shop status updated")
        }
        else {
            console.log(data)
            console.log("can not update shop status")
        }
        this.getCurrentBalance()
    }

    onChangeAmount = (event) => {
        this.setState({ walletAmount: event.target.value })
    }

    //AMOUNT WITHDRAW

    onClickWithDraw = async () => {
        const { withdrawlAmount } = this.state
        if(withdrawlAmount<10){
            alert("amount should be greater than 10Rs")
            return 
        }
        this.setState({ withdrawalStatus: withdrawalStatusConstants.inProgress })
        const details = { amount: withdrawlAmount }
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/wallet/withdrawal-request`;
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(details)
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            this.setState({ withDrawResult: data, withdrawlAmount: 0,  withdrawalStatus: withdrawalStatusConstants.completed })
        }
        else {
            this.setState({withDrawResult: data, withdrawlAmount: 0, withdrawalStatus: withdrawalStatusConstants.completed })
            console.log("can not update shop status")
        }
        this.getCurrentBalance()
    }

    onChangeSearchValue = event => {
        this.setState({ searchValue: event.target.value })
    }

    onClickRetry = () => {
        window.location.reload()
    }

    onChangeType = (event) => {
        this.setState({ selectedOption: event.target.value })
    }

    onChangeWithdrawlAmount = (event) => {
        this.setState({ withdrawlAmount: event.target.value })
    }

    //orderItem page handlers................................................

    //printed btn status updation

    handelPrintedBtn = async (orderId) => {
        const token = Cookies.get("mitra_token");
        const details = { status: "Printed" };
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders/${orderId}/status`;

        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(details),
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json()
            if (response.ok) {
                console.log('Status updated successfully');
            } else {
                console.log(data)
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error during status update:', error);
        }
        this.getData()
    }

    //CANCEL btn

    handelCancelBtn = async (orderId) => {
        const token = Cookies.get("mitra_token");
        const details = { status: "Rejected" };
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders/${orderId}/status`;

        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(details),
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json()
            if (response.ok) {
                console.log('Status updated successfully');
            } else {
                console.log(data)
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error during status update:', error);
        }
        this.getData()
    }

    //COMPLETED BTN

    handelCompletedBtn = async (orderId, otp) => {
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/orders/${orderId}/status`;
        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                status: 'Completed',
                otpQuery: `${otp}`
            })
        }
        const response = await fetch(url, options)
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            alert("your order is completed")
        }
        else {
            alert("your order is not completed")
        }
        this.getData()
    }

    renderLoadingView = () => (
        <div className='loader-spinner'>
            <Oval height={80} width={80} color="blue" ariaLabel="loading" />
        </div>
    )

    renderWithDrawlCompleted = () => {
        const { withDrawResult } = this.state
        return (
            <div>
                <p>{withDrawResult.msg}</p>
            </div>
        )
    }

    renderWithDrawlInitialView = () => {
        const {withdrawlAmount} = this.state
        return (
            <div >
                <p>Enter Amount:</p>
                <input type="number" value={withdrawlAmount} onChange={this.onChangeWithdrawlAmount} placeholder='ENTER AMOUNT' /> <br />
                <div className="popup-btn-container">
                    <button type="button" className="print-all-btn" onClick={this.onClickWithDraw}>Withdraw</button>
                </div>
            </div>
        )
    }

    onClickClose = (close) => {
        this.setState({withdrawalStatus: withdrawalStatusConstants.initial})
        close()
    }


    render() {
        const { isActive, searchValue, list, status, selectedOption, walletDetails, withdrawalStatus } = this.state
        const { shopId, walletBalance, shopAddress } = walletDetails
        let newList;
        if (selectedOption === "all") {
            newList = list.filter(eachItem => {
                if (eachItem.status === "Printed" || eachItem.status === "Confirmed" || eachItem.status === "Pending") {
                    return eachItem
                }
                else {
                    return null
                }
            })
        } else if (selectedOption === "printed") {
            newList = list.filter(eachItem => {
                if (eachItem.status === "Printed") {
                    return eachItem
                }
                else {
                    return null
                }
            })
        }
        else {
            newList = list.filter(eachItem => {
                if (eachItem.status === "Confirmed") {
                    return eachItem
                }
                else {
                    return null
                }
            })
        }

        const searchedList = newList.filter(eachItem => {
            const customOrderIdNumber = eachItem.customOrderId.replace(/\D/g, '');
            return (
                customOrderIdNumber.includes(searchValue) || 
                eachItem.customerName.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        

        let code

        switch (status) {
            case apiStatus.inProgress:
                code = (
                    <div className='loader-spinner'>
                        <Oval height={80} width={80} color="blue" ariaLabel="loading" />
                    </div>
                )
                break
            case apiStatus.success:
                if (searchedList.length === 0) {
                    code = (
                        <div className="no-orders-view">
                            <div className='head-container'>
                                <h1 className="orders-heading">Orders:</h1>
                                <select className='drop-down' id="options" value={selectedOption} onChange={this.onChangeType}>
                                    <option value="all">ALL</option>
                                    <option value="printed">PRINTED</option>
                                    <option value="pending">NOT PRINTED</option>
                                </select>
                            </div>
                            <img
                                src="https://assets.ccbp.in/frontend/react-js/no-jobs-img.png"
                                alt="no orders"
                                className='no-orders-view-img'
                            />
                            <h1>No Orders Found</h1>
                            <p>We could not find any Orders.</p>
                        </div>
                    )
                } else {
                    code = (
                        <ul className="orders-list-container">
                            <div className='head-container'>
                                <h1 className="orders-heading">Orders:</h1>
                                <select className='drop-down' id="options" value={selectedOption} onChange={this.onChangeType}>
                                    <option value="all">ALL</option>
                                    <option value="printed">PRINTED</option>
                                    <option value="pending">NOT PRINTED</option>
                                </select>
                            </div>
                            {searchedList.map(eachItem => <OrderItem value={eachItem} key={eachItem.orderId} handelPrintedBtn={this.handelPrintedBtn} handelCancelBtn={this.handelCancelBtn} handelCompletedBtn={this.handelCompletedBtn} />)}
                        </ul>
                    )
                }
                break
            case apiStatus.failure:
                code = (
                    <div className="no-orders-view">
                        <img
                            src="https://assets.ccbp.in/frontend/react-js/failure-img.png"
                            alt="failure view"
                            className='no-orders-view-img'
                        />
                        <h1>Oops! Something Went Wrong</h1>
                        <p>We cannot seem to find the page you are looking for</p>
                        <button className="retry-btn" onClick={this.onClickRetry} type="button">
                            Retry
                        </button>
                    </div>
                )
                break
            default:
                code = null
        }

        let withDrawlView

        switch (withdrawalStatus) {
            case withdrawalStatusConstants.completed:
                withDrawlView = this.renderWithDrawlCompleted()
                break
            case withdrawalStatusConstants.inProgress:
                withDrawlView = this.renderLoadingView()
                break
            case withdrawalStatusConstants.initial:
                withDrawlView = this.renderWithDrawlInitialView()
                break
            default:
                withDrawlView = null
        }

        return (
            <div>
                <Header />
                <div className="main-content">
                    <div className="upper-container">
                        <Popup
                            modal
                            trigger={
                                <div className="wallet-container">
                                    <IoWalletOutline className='wallet-img' />
                                    <p className="amount">{walletBalance}</p>
                                </div>
                            }
                        >
                            {close => (
                                <div className='completed-popup'>
                                    {withDrawlView}
                                        <button type="button" className="print-all-btn" onClick={() => this.onClickClose(close)}>
                                                close
                                        </button>
                                </div>
                            )}
                        </Popup>
                        <Link to="/transactionhistory">
                            <div>
                                <p>Last Transactions</p>
                            </div>
                        </Link>
                        <div>
                            <p className='store-details'>STORE ID: <span className='span-store'>{shopId}</span></p>
                            <p className='store-details'>LOCATION: <span className='span-store'>{shopAddress}</span></p>
                        </div>
                        <div>
                            <Switch className='switch' checked={isActive} onChange={this.onChangeActive} checkedChildren="Opened" unCheckedChildren="Closed" />
                        </div>
                    </div>
                    <div className='search-container'>
                        <div className="search-inp-container">
                            <FiSearch />
                            <input type="search" placeholder='search by order is/user name' value={searchValue} onChange={this.onChangeSearchValue} className="search-inp" />
                        </div>
                    </div>
                    <div>
                        {code}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Home 