import { Component } from 'react'
import Cookies from 'js-cookie'
import Popup from 'reactjs-popup'
import Header from '../Header'

import './index.css'

class ProfilePage extends Component {
    state = {
        shopId: '',
        newUpi: '',
        upiPassword: '',
        blackAndWhiteId: '',
        colorId: '',
        blackAndWhiteOneSide: 0,
        blackAndWhiteTwoSide: 0,
        colorOneSide: 0,
        shopName: '',
        city: '',
        mobileNo: '',
        mailId: '',
        shopErrMsg: '',
        ServicesErrMsg: '',
        bulkThreshold: 0,
        bulkPrice: 0,
    }

    componentDidMount = () => {
        this.getData()
    }

    getData = async () => {
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/profile/shop-profile`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "GET"
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok) {
            console.log(data)
            const { shopId, shopName, shopAddress, mobileNo, services, email } = data.shop;
            const colorServiceId = services.find(service => service.name === 'color')
            const blackANdWhiteServiceId = services.find(service => service.name === 'black&white')
            console.log(colorServiceId.serviceId)
            console.log(blackANdWhiteServiceId.serviceId)
            this.setState({
                shopId: shopId, city: shopAddress, shopName: shopName, mobileNo: mobileNo, mailId: email, colorId: colorServiceId.serviceId, blackAndWhiteId: blackANdWhiteServiceId.serviceId, blackAndWhiteOneSide: blackANdWhiteServiceId.price.singleSide, blackAndWhiteTwoSide: blackANdWhiteServiceId.price.doubleSide, colorOneSide: colorServiceId.price.singleSide,
                bulkThreshold: blackANdWhiteServiceId.bulkThreshold,
                bulkPrice: blackANdWhiteServiceId.bulkPrice
            })
        }
        else {
            console.log(data)
        }
    }

    onInputChange = (event) => {
        const { id, value } = event.target;
        this.setState({ [id]: value }, () => {
            if (id === 'bulkThreshold' && parseInt(value) === 0) {
                this.setState({ bulkPrice: 0 });
            }
        });
    };
    

    profileValidations = () => {
        const { shopName, city, mobileNo } = this.state
        if (shopName === '') return '*Please enter the shopName'
        else if (city === '') return '*Please enter the city'
        else if (mobileNo.length !== 10) return '*mobileNo digits must be 10'
        else return ''
    }

    onClickSaveProfile = async () => {
        const { shopName, city, mobileNo } = this.state
        const validated = this.profileValidations()
        if (validated !== '') {
            this.setState({ shopErrMsg: validated })
            return
        }
        else {
            this.setState({ shopErrMsg: '' })
        }
        const details = { shopName, shopAddress: city, mobileNo }
        const token = Cookies.get("mitra_token")
        const url = `${process.env.REACT_APP_BASE_URL}/api/profile/shop-profile-update`
        const options = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(details),
        }
        const response = await fetch(url, options);
        const data = await response.json()
        if (response.ok) {
            alert("Profile is Updated")
        }
        else {
            console.log(data)
            alert("cant able update profile")
        }
    }

    onClickSaveServices = async () => {
        const { blackAndWhiteId, colorId, blackAndWhiteOneSide, blackAndWhiteTwoSide, colorOneSide, shopId, bulkPrice, bulkThreshold } = this.state
    
        // Validate Black and White prices
        if (blackAndWhiteOneSide === '' || blackAndWhiteTwoSide === '') {
            this.setState({ ServicesErrMsg: "*Enter the B&W cost" })
            return
        } else if (blackAndWhiteOneSide <= 0 || blackAndWhiteTwoSide <= 0) {
            this.setState({ ServicesErrMsg: "Cost must be greater than 0" })
            return
        }
    
        // Validate Color prices
        if (colorOneSide === '') {
            this.setState({ ServicesErrMsg: "*Enter the Color cost" })
            return
        } else if (colorOneSide <= 0) {
            this.setState({ ServicesErrMsg: "Cost must be greater than 0" })
            return
        }
    
        // Validate bulk pricing
        if ((bulkThreshold > 0 && bulkPrice === 0) || (bulkThreshold === 0 && bulkPrice > 0)) {
            this.setState({ ServicesErrMsg: "Both bulk threshold and bulk price must be greater than 0 or both should be 0" })
            return
        }
    
        // Clear error message if validations pass
        this.setState({ ServicesErrMsg: '' })
    
        const token = Cookies.get("mitra_token")
    
        // Black and white service details
        const BlackAndWhiteDetails = {
            price: {
                singleSide: blackAndWhiteOneSide,
                doubleSide: blackAndWhiteTwoSide
            },
            bulkPricing: {
                bulkThreshold,
                bulkPrice
            }
        }
    
        const BlackAndWhiteUrl = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/update-services/${blackAndWhiteId}`
        const BlackAndWhiteOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify(BlackAndWhiteDetails)
        }
    
        const BlackAndWhiteResponse = await fetch(BlackAndWhiteUrl, BlackAndWhiteOptions)
        const BlackAndWhiteData = await BlackAndWhiteResponse.json()
    
        if (BlackAndWhiteResponse.ok) {
            if(bulkPrice===0 && bulkThreshold===0){
                alert("Bluk service has been disabled")
            }else{
            alert("Black and White services updated successfully")}
        } else {
            console.log(BlackAndWhiteData)
        }
    
        // Color service details
        const colorDetails = { price: { singleSide: colorOneSide } }
        const colorUrl = `${process.env.REACT_APP_BASE_URL}/shopservices/xeroxshops/${shopId}/update-services/${colorId}`
        const colorOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify(colorDetails)
        }
    
        const colorResponse = await fetch(colorUrl, colorOptions)
        const colorData = await colorResponse.json()
    
        if (colorResponse.ok) {
            alert("Color services updated successfully")
        } else {
            console.log(colorData)
        }
        this.getData()
    }


    //chage UPI ID
    onClickUpiProceed = async () => {
        const token = Cookies.get("mitra_token")
        const { upiPassword, newUpi } = this.state
        const details = { password: upiPassword, upi: newUpi }
        const url = `${process.env.REACT_APP_BASE_URL}/profile/shop-profile-update/upi`
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(details)
        }
        const response = await fetch(url, options)
        const upiData = await response.json()
        if (response.ok) {
            alert(upiData.message)
            this.setState({ newUpi: '', upiPassword: '' })
        }
        else {
            alert(upiData.error)
        }
    }


    render() {
        const { newUpi, upiPassword, blackAndWhiteOneSide, blackAndWhiteTwoSide, colorOneSide, mailId, shopName, city, mobileNo, shopErrMsg, ServicesErrMsg, bulkThreshold, bulkPrice } = this.state
        return (
            <div>
                <Header />
                <div className='profile-page'>
                    <div className='services-container'>
                        <h1>Shop Details</h1>
                        <p>Information you provide will be used for the purpose of privacy and security.</p>
                        <div className=''>
                            <div className='shop-service-boxes'>
                                <div className='input-set'>
                                    <label className='shop-profile-label' htmlFor="shopName">Shop Name</label><br />
                                    <input className='shop-profile-input' value={shopName} onChange={this.onInputChange} placeholder='Enter Name' type="text" id="shopName" />
                                </div>
                                <div className='input-set'>
                                    <label className='shop-profile-label' htmlFor="city">City</label><br />
                                    <input className='shop-profile-input' value={city} onChange={this.onInputChange} placeholder='Enter City' type="text" id="city" />
                                </div>
                            </div>
                            <div className='shop-service-boxes'>
                                <div className='input-set'>
                                    <label className='shop-profile-label' htmlFor="mobileNo">Mobile No</label><br />
                                    <input className='shop-profile-input' value={mobileNo} onChange={this.onInputChange} placeholder='Enter Mobile No' type="number" id="mobileNo" />
                                </div>
                                <div className='input-set'>
                                    <label className='shop-profile-label' htmlFor="mailId">Gmail</label><br />
                                    <input className='shop-profile-input' value={mailId} onChange={this.onInputChange} placeholder='Enter Mail' type="text" />
                                    <p className="email-note">You cannot update email address</p>
                                </div>
                            </div>
                        </div>
                        <button onClick={this.onClickSaveProfile} className='save-btn'>Save</button>
                        <Popup
                            modal
                            trigger={
                                <button type="button" className="upi-btn">Change UPI ID</button>
                            }
                        >
                            {close => (
                                <>
                                    <div className='upi-completed-popup'>
                                        <div className='upi-service-boxes'>
                                            <div className='input-set'>
                                                <label className='profile-label' htmlFor="upiPassword">PASSWORD :</label><br />
                                                <input className='profile-input' value={upiPassword} onChange={this.onInputChange} placeholder='ENTER PASSWORD' type="password" id="upiPassword" />
                                            </div>
                                            <div className='input-set'>
                                                <label className='profile-label' htmlFor="newUpi">NEW UPI ID :</label><br />
                                                <input className='profile-input' value={newUpi} onChange={this.onInputChange} placeholder='ENTER UPI ID' type="text" id="newUpi" />
                                            </div>
                                        </div>
                                        <div className="popup-btn-container">
                                            <button type="button" className="print-all-btn" onClick={() => close()}>
                                                Cancel
                                            </button>
                                            <button type="button" className="print-all-btn" onClick={this.onClickUpiProceed}>Proceed</button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Popup>
                        {shopErrMsg !== '' && <p className='save-error-msg'>{shopErrMsg}</p>}
                    </div>
                    <div className='services-container'>
                        <h1>Shop Services</h1>
                        <p>We will use the details you provide to ensure customers can access and use your services.</p>
                        <div className=''>
                            <h1 className="sub-heading">BLACK AND WHITE</h1>
                            <div className='service-boxes'>
                                <div className='input-set'>
                                    <label className='profile-label' htmlFor="blackAndWhiteOneSide">One Side :</label><br />
                                    <input className='profile-input' value={blackAndWhiteOneSide} onChange={this.onInputChange} placeholder='Enter Price' type="number" id="blackAndWhiteOneSide" />
                                </div>
                                <div className='input-set'>
                                    <label className='profile-label' htmlFor="blackAndWhiteTwoSide">Two Sides :</label><br />
                                    <input className='profile-input' value={blackAndWhiteTwoSide} onChange={this.onInputChange} placeholder='Enter Price' type="number" id="blackAndWhiteTwoSide" />
                                </div>
                            </div>
                            <div className='service-boxes'>
                                <div className='input-set'>
                                    <label className='profile-label' htmlFor="bulkThreshold">Bulk threshold :</label><br />
                                    <input className='profile-input' value={bulkThreshold} onChange={this.onInputChange} placeholder='Enter Price' type="number" id="bulkThreshold" />
                                </div>
                                <div className='input-set'>
                                    <label className='profile-label' htmlFor="bulkPrice">Price for bulk :</label><br />
                                    <input className='profile-input' value={bulkPrice} onChange={this.onInputChange} placeholder='Enter Price' type="number" id="bulkPrice" />
                                </div>
                            </div>
                            <h1 className="sub-heading">COLOUR</h1>
                            <div className='service-boxes'>
                                <div className='input-set'>
                                    <label className='profile-label' htmlFor="colorOneSide">One Side :</label><br />
                                    <input className='profile-input' value={colorOneSide} onChange={this.onInputChange} placeholder='Enter Price' type="number" id="colorOneSide" />
                                </div>
                            </div>
                        </div>
                        <button onClick={this.onClickSaveServices} className='save-btn'>Save</button>
                        {ServicesErrMsg !== '' && <p className='save-error-msg'>{ServicesErrMsg}</p>}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilePage