import { RxCross2 } from "react-icons/rx";
import { LiaPiggyBankSolid } from "react-icons/lia";

import './index.css'

const TransactionHistoryItem = props =>{
    const {value} = props 
    const {amount,status,_id,createdAt} = value

    return(
        <li className="history-list-item">
            <div className="history-item-details">
                <h1 className="history-name">{createdAt}</h1>
                <p className="history-email">{_id}</p>
            </div>
            <p className="history-type">{status}</p>
            <div className="items">
                <LiaPiggyBankSolid className="pig" />
                <p className="price">-{amount} rs</p>
                <button className="price-btn">-{amount} rs</button>
                <button type="button" className="cross-btn"><RxCross2 /></button>               
            </div>
        </li>
    )
}

export default TransactionHistoryItem